export const DASHBOARD_TAGS = {
    SUMMERY: 'dashboard_summery',
    LAST_SENT: 'dashboard_last_sent',
    RECENTLY_SENT: 'dashboard_recently_sent',
};
export const USER_TAGS = {
    GRAPH_IMAGE: 'user_graph_image',
    GET_PRIORITY_USER_COUNT: 'get_priority_user_count',
    GET_USER_BY_ID: 'get_user_by_id',
    GET_LOGGED_IN_USER: 'get_logged_in_user',
    GET_LOGGED_IN_USER_DEPARTMENT_IDS_WITH_CHILD: 'get_logged_in_user_department_ids_with_child',
};
export const BRANCH_TAGS = {
    ALL_BRANCHES: 'all_branches',
    ALL_PREFECTURES: 'all_prefectures',
};
export const DEPARTMENT_TAGS = {
    ALL_DEPARTMENTS: 'all_departments',
    ALL_DEPARTMENTS_BY_HIERARCHY: 'all_departments_by_hierarchy',
};
export const SURVEY_RESULT_TAGS = {
    GET_PANDEMIC_INFO_BY_DPT: 'get_pandemic_info_by_dpt',
    GET_PANDEMIC_INFO_BY_BRANCH: 'get_pandemic_info_by_branch',
    GET_USER_INFO_BY_PANDEMIC: 'get_user_info_by_pandemic',
    GET_PANDEMIC_QUESTIONS: 'get_pandemic_questions',
    GET_PANDEMIC_QUESTIONS_BY_DPT: 'get_pandemic_questions_by_dpt',
    GET_PANDEMIC_QUESTIONS_BY_BRANCH: 'get_pandemic_questions_by_branch',
};
export const DELIVERY_SETTINGS_TAGS = {
    GET_REGISTERED_INTENSITY_SETTINGS: 'get_registered_intensity_settings',
};
export const TAGS = [
    ...Object.values(DASHBOARD_TAGS),
    ...Object.values(USER_TAGS),
    ...Object.values(BRANCH_TAGS),
    ...Object.values(DEPARTMENT_TAGS),
    ...Object.values(SURVEY_RESULT_TAGS),
    ...Object.values(DELIVERY_SETTINGS_TAGS),
];

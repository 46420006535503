import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AUTH_REDUCER } from 'store/name';
import { setAccessTokenToLocalStorage } from 'utils/common';
import { TOKEN, API_BASE_URL, USER_ROLE_INFO } from 'utils/constants';
import Language from 'utils/language';
import { setUserLoggedInStatus } from './index';
export const getAccessToken = createAsyncThunk(`${AUTH_REDUCER}/getAccessToken`, async (currentUserUpn, { rejectWithValue }) => {
    try {
        const tokenObj = JSON.parse(localStorage.getItem(TOKEN) || '');
        if (tokenObj) {
            const now = new Date();
            const expireDate = new Date(tokenObj.expireAt);
            if (expireDate > now) {
                return tokenObj.token;
            }
        }
        const res = await axios.get(`${window.location.origin + '/api'}/user-token}`, {
            params: {
                upn: currentUserUpn,
            },
        });
        setAccessTokenToLocalStorage(res.data);
        return res.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAccessTokenByCode = createAsyncThunk(`${AUTH_REDUCER}/getAccessTokenByCode`, async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/token-by-code`, data, {
            headers: { 'Content-Type': 'application/json' },
        });
        setAccessTokenToLocalStorage(response.data);
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getUserRoleInfo = createAsyncThunk(`${AUTH_REDUCER}/getUserRoleInfo`, async (token, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user-info/user-role-info`, { Token: token });
        localStorage.setItem(USER_ROLE_INFO, JSON.stringify(response.data));
        dispatch(setUserLoggedInStatus('loggedIn'));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAccessTokenByUserPassword = createAsyncThunk(`${AUTH_REDUCER}/getAccessTokenByUserPassword`, async (data, { rejectWithValue }) => {
    try {
        const tokenObj = JSON.parse(localStorage.getItem(TOKEN));
        if (tokenObj) {
            const now = new Date();
            const expireDate = new Date(tokenObj.expireAt);
            if (expireDate > now) {
                return tokenObj.token;
            }
        }
        const userCredential = `grant_type=password&userName=${data.userName}&password=${data.password}`;
        const res = await axios.post(`${API_BASE_URL}/Token`, userCredential, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const output = res.data.access_token;
        setAccessTokenToLocalStorage(output, false);
        localStorage.setItem(USER_ROLE_INFO, JSON.stringify({
            UserPrincipalName: data.userName,
            IsAdmin: res.data.isAdmin,
            IsO365: false,
        }));
        return output;
    }
    catch (e) {
        if (e.response.data.error === 'invalid_grant') {
            return rejectWithValue(Language.jap.InvalidEmailPassword);
        }
        else {
            return rejectWithValue(Language.jap.CommonErrorMsg);
        }
    }
});

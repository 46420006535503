import { createAsyncThunk } from '@reduxjs/toolkit';
import { NORMAL_USER_REDUCER } from 'store/name';
import { setTotalData } from 'store/reducers/ui/pagination';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
export const getLastSentForFamily = createAsyncThunk(`${NORMAL_USER_REDUCER}/getLastSentForFamily`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/pandemic/last-sent-family');
        if (!!response.data) {
            const { FamilyMembers, ...others } = response.data;
            return {
                ...others,
                FamilyMembers: FamilyMembers.map((data) => ({
                    ...data,
                    IsDeletable: true,
                })),
            };
        }
        else {
            return null;
        }
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getSurveyListForFamily = createAsyncThunk(`${NORMAL_USER_REDUCER}/getSurveyListForFamily`, async ({ startDate, endDate, ...others }, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/pandemic/family', {
            params: {
                ...others,
                startDate: startDate || '',
                endDate: endDate || '',
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        return response.data.Results.map((data) => ({
            ...data,
            IsDeletable: true,
        }));
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getFamilyResponseByPandemic = createAsyncThunk(`${NORMAL_USER_REDUCER}/getFamilyResponseByPandemic`, async (id, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/pandemic/pandemic-response-family', {
            params: {
                id,
            },
        });
        if (!!response.data) {
            const { FamilyMembers, ...others } = response.data;
            return {
                ...others,
                FamilyMembers: FamilyMembers.map((data) => ({
                    ...data,
                    IsDeletable: true,
                })),
            };
        }
        else {
            return null;
        }
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

import { baseApi } from '../base-api';
import { DELIVERY_SETTINGS_TAGS } from '../tags';
const deliverySettingsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getRegisteredSettingsIntensity: build.query({
            query: () => ({
                method: 'get',
                url: '/automatic-delivery-setting/get-registered-intensity-and-settings',
            }),
            providesTags: [DELIVERY_SETTINGS_TAGS.GET_REGISTERED_INTENSITY_SETTINGS],
        }),
    }),
    overrideExisting: false,
});
export const { useGetRegisteredSettingsIntensityQuery } = deliverySettingsApi;
export default deliverySettingsApi;

import { createSlice } from '@reduxjs/toolkit';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { DEPARTMENT_REDUCER } from 'store/name';
import { EMPTY_GUID } from 'utils/constants';
import { addEditNewDepartment, deleteMultipleDepartment, deleteSingleDepartmentById, getAllDepartments, getDepartmentInfoById, getDepartmentListPaged, } from './api';
const initialState = {
    departmentList: [],
    stopScrolling: false,
    departmentListPaged: [],
    errorMessage: null,
    singleDepartmentInfo: undefined,
    getDepartmentListPagedStatus: 'initial',
    getSingleDepartmentStatus: 'initial',
    getAddEditDepartmentStatus: 'initial',
    multipleDeleteStatus: 'initial',
    totalDeletable: 0,
};
const departmentSlice = createSlice({
    name: DEPARTMENT_REDUCER,
    initialState,
    reducers: {
        resetDepartment: (state) => {
            state.departmentListPaged = [];
        },
        resetSelectedDepartment: (state) => {
            state.singleDepartmentInfo = undefined;
        },
    },
    extraReducers: (builder) => builder
        .addCase(getAllDepartments.fulfilled, (state, action) => {
        state.departmentList = action.payload;
    })
        .addCase(getDepartmentListPaged.pending, (state) => {
        state.getDepartmentListPagedStatus = 'pending';
    })
        .addCase(getDepartmentListPaged.fulfilled, (state, action) => {
        const { Results, TotalDeletable } = action.payload;
        state.departmentListPaged = Results;
        state.totalDeletable = TotalDeletable;
        state.getDepartmentListPagedStatus = 'success';
    })
        .addCase(getDepartmentListPaged.rejected, (state) => {
        state.getDepartmentListPagedStatus = 'rejected';
    })
        .addCase(addEditNewDepartment.pending, (state) => {
        state.getAddEditDepartmentStatus = 'pending';
    })
        .addCase(addEditNewDepartment.fulfilled, (state, action) => {
        if (action.meta.arg.Id !== EMPTY_GUID) {
            state.departmentListPaged = state.departmentListPaged.map((department) => {
                if (department.Id === action.meta.arg.Id) {
                    return action.payload;
                }
                return department;
            });
        }
        state.getAddEditDepartmentStatus = 'success';
    })
        .addCase(addEditNewDepartment.rejected, (state) => {
        state.getAddEditDepartmentStatus = 'rejected';
    })
        .addCase(getDepartmentInfoById.pending, (state) => {
        state.getSingleDepartmentStatus = 'pending';
    })
        .addCase(getDepartmentInfoById.fulfilled, (state, action) => {
        state.singleDepartmentInfo = action.payload;
        state.getSingleDepartmentStatus = 'success';
    })
        .addCase(getDepartmentInfoById.rejected, (state) => {
        state.getSingleDepartmentStatus = 'rejected';
    })
        .addCase(deleteSingleDepartmentById.fulfilled, (state, action) => {
        state.totalDeletable -= 1;
        state.departmentListPaged = state.departmentListPaged.filter((department) => department.Id !== action.payload);
    })
        .addCase(deleteMultipleDepartment.pending, (state) => {
        state.multipleDeleteStatus = 'pending';
    })
        .addCase(deleteMultipleDepartment.fulfilled, (state, action) => {
        state.totalDeletable = state.totalDeletable - action.payload.length;
        state.departmentListPaged = state.departmentListPaged.filter((department) => !action.payload.includes(department.Id));
        state.multipleDeleteStatus = 'success';
    })
        .addCase(deleteMultipleDepartment.rejected, (state) => {
        state.multipleDeleteStatus = 'rejected';
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { resetDepartment, resetSelectedDepartment } = departmentSlice.actions;
export default departmentSlice.reducer;

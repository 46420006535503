import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { DELIVERY_SETTINGS_REDUCER } from 'store/name';
import { getDeliverySettingsListPaged, getDeliverySettingsInfoById, deleteDeliverySettings, getEarthquakeIntensity, addDeliverySettings, updateDeliverySettings, deleteMultiDeliverySettings, } from './api';
const initialState = {
    deliverySettingsListPaged: [],
    errorMessage: null,
    registeredIntensity: [],
    singleDeliverySettingsInfo: null,
    stopScrolling: false,
    multipleDeleteDeliveryStatus: 'initial',
    totalDeletableEarthquake: 0,
    totalDeletableRainfall: 0,
    getDeliverySettingsApiStatus: 'initial',
};
const deliverySettingsSlice = createSlice({
    name: DELIVERY_SETTINGS_REDUCER,
    initialState,
    reducers: {
        setSingleDeliverySettingsNull: (state) => {
            state.singleDeliverySettingsInfo = null;
        },
    },
    extraReducers: (builder) => builder
        .addCase(getDeliverySettingsListPaged.pending, (state) => {
        state.getDeliverySettingsApiStatus = 'pending';
    })
        .addCase(getDeliverySettingsListPaged.fulfilled, (state, action) => {
        if (action.payload === null) {
            state.deliverySettingsListPaged = [];
        }
        else {
            const { Results, TotalDeletableRainfall, TotalDeletableEarthquake } = action.payload;
            state.deliverySettingsListPaged = Results;
            state.totalDeletableEarthquake = TotalDeletableEarthquake;
            state.totalDeletableRainfall = TotalDeletableRainfall;
            state.getDeliverySettingsApiStatus = 'success';
        }
    })
        .addCase(getDeliverySettingsListPaged.rejected, (state) => {
        state.getDeliverySettingsApiStatus = 'rejected';
    })
        .addCase(getDeliverySettingsInfoById.fulfilled, (state, action) => {
        state.singleDeliverySettingsInfo = action.payload;
    })
        .addCase(getDeliverySettingsInfoById.rejected, (state, action) => {
        state.errorMessage = action.payload;
    })
        .addCase(deleteDeliverySettings.fulfilled, (state, action) => {
        state.deliverySettingsListPaged = state.deliverySettingsListPaged.filter((deliverySettings) => deliverySettings.Id !== action.payload);
    })
        .addCase(deleteDeliverySettings.rejected, (state, action) => {
        state.errorMessage = action.payload;
    })
        .addCase(deleteMultiDeliverySettings.pending, (state) => {
        state.multipleDeleteDeliveryStatus = 'pending';
    })
        .addCase(deleteMultiDeliverySettings.fulfilled, (state, action) => {
        state.deliverySettingsListPaged = state.deliverySettingsListPaged.filter((deliverySettings) => !action.payload.includes(deliverySettings.Id));
        state.multipleDeleteDeliveryStatus = 'success';
    })
        .addCase(deleteMultiDeliverySettings.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.multipleDeleteDeliveryStatus = 'rejected';
    })
        .addCase(getEarthquakeIntensity.fulfilled, (state, action) => {
        state.registeredIntensity = _.map(action.payload, (item) => item);
    })
        .addCase(getEarthquakeIntensity.rejected, (state, action) => {
        state.errorMessage = action.payload;
    })
        .addCase(addDeliverySettings.fulfilled, (state, action) => {
        state.deliverySettingsListPaged = state.deliverySettingsListPaged.concat(action.payload);
    })
        .addCase(addDeliverySettings.rejected, (state, action) => {
        state.errorMessage = action.payload;
    })
        .addCase(updateDeliverySettings.fulfilled, (state, action) => {
        state.deliverySettingsListPaged = state.deliverySettingsListPaged.map((deliverySettings) => deliverySettings.Id === action.payload.Id ? action.payload : deliverySettings);
    })
        .addCase(updateDeliverySettings.rejected, (state, action) => {
        state.errorMessage = action.payload;
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { setSingleDeliverySettingsNull } = deliverySettingsSlice.actions;
export default deliverySettingsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { TOAST_REDUCER } from 'store/name';
const initialState = {
    severity: null,
    message: null,
    open: false,
};
const toastSlice = createSlice({
    name: TOAST_REDUCER,
    initialState,
    reducers: {
        handleToastOpt: (state, action) => {
            const { message, open, severity } = action.payload;
            state.message = message;
            state.severity = severity;
            state.open = open;
        },
    },
    extraReducers: () => { },
});
export const { handleToastOpt } = toastSlice.actions;
export default toastSlice.reducer;

import { baseApi } from '../base-api';
import { DASHBOARD_TAGS } from '../tags';
const dashboardApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDashboardSummary: build.query({
            query: () => {
                return {
                    url: '/dashboard/summary',
                    method: 'get',
                };
            },
            providesTags: [DASHBOARD_TAGS.SUMMERY],
        }),
        getLastQuestionDetails: build.query({
            query: () => {
                return {
                    url: '/pandemic/last-sent',
                    method: 'get',
                };
            },
            providesTags: [DASHBOARD_TAGS.LAST_SENT],
        }),
        getRecentlySentQuestionnaire: build.query({
            query: () => {
                return {
                    url: '/pandemic/recently-sent',
                    method: 'get',
                };
            },
            providesTags: [DASHBOARD_TAGS.RECENTLY_SENT],
        }),
    }),
    overrideExisting: false,
});
export const { useGetDashboardSummaryQuery, useGetLastQuestionDetailsQuery, useGetRecentlySentQuestionnaireQuery } = dashboardApi;
export default dashboardApi;

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from 'store/reducers/resources/auth';
import branchReducer from 'store/reducers/resources/branch';
import deliverySettingsReducer from 'store/reducers/resources/delivery-settings';
import departmentReducer from 'store/reducers/resources/department';
import normalUserReducer from 'store/reducers/resources/normal-user';
import surveyReducer from 'store/reducers/resources/survey';
import templateReducer from 'store/reducers/resources/template';
import userReducer from 'store/reducers/resources/user';
import drawerReducer from 'store/reducers/ui/drawer';
import multiDeleteReducer from 'store/reducers/ui/multi-delete';
import paginationReducer from 'store/reducers/ui/pagination';
import filterParamsReducer from 'store/reducers/ui/specific-question';
import toastReducer from 'store/reducers/ui/toast';
import { baseApi } from 'store/rtk-query/base-api';
import authApi from 'store/rtk-query/custom/auth-api';
export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
        user: userReducer,
        survey: surveyReducer,
        branch: branchReducer,
        department: departmentReducer,
        template: templateReducer,
        deliverySettings: deliverySettingsReducer,
        normalUser: normalUserReducer,
        toast: toastReducer,
        specificQuestionFilterParams: filterParamsReducer,
        drawer: drawerReducer,
        pagination: paginationReducer,
        multiDelete: multiDeleteReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware, authApi.middleware),
});
setupListeners(store.dispatch);

import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { TEMPLATE_REDUCER } from 'store/name';
import { addNewQuestion } from '../survey/api';
import { deleteTemplate, getAllTemplateFromDB, getTemplateById, copyTemplate, updateTemplate, createDynamicTemplate, deleteMultiTemplate, } from './api';
const initialState = {
    dynamicTemplate: undefined,
    errMsg: null,
    listOfTemplates: [],
    singleTemplate: undefined,
    formHasError: false,
    deleteMultiTemplateStatus: 'initial',
    getSingleTemplateStatus: 'initial',
    getTemplateListStatus: 'initial',
    totalDeletable: 0,
    copiedTemplateOriginalName: '',
};
const templateSlice = createSlice({
    name: TEMPLATE_REDUCER,
    initialState,
    reducers: {
        setSingleTemplateToNull: (state) => {
            state.singleTemplate = null;
        },
        setSingleTemplateFromSelection: (state, action) => {
            state.singleTemplate = action.payload;
        },
        setHasFormError: (state, action) => {
            state.formHasError = action.payload;
        },
        setOriginalName: (state, action) => {
            state.copiedTemplateOriginalName = action.payload;
        },
    },
    extraReducers: (builder) => builder
        .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.totalDeletable -= 1;
        state.listOfTemplates = _.filter(state.listOfTemplates, (template) => template.Id !== action.payload);
    })
        .addCase(deleteMultiTemplate.pending, (state) => {
        state.deleteMultiTemplateStatus = 'pending';
    })
        .addCase(deleteMultiTemplate.fulfilled, (state, action) => {
        state.totalDeletable = state.totalDeletable - action.payload.length;
        state.listOfTemplates = state.listOfTemplates.filter((x) => !action.payload.includes(x.Id));
        state.deleteMultiTemplateStatus = 'success';
    })
        .addCase(deleteMultiTemplate.rejected, (state, action) => {
        state.deleteMultiTemplateStatus = 'rejected';
        state.errMsg = action.payload;
    })
        .addCase(getAllTemplateFromDB.pending, (state) => {
        state.getTemplateListStatus = 'pending';
    })
        .addCase(getAllTemplateFromDB.fulfilled, (state, action) => {
        if (action.payload === null) {
            state.listOfTemplates = [];
        }
        else {
            const { Results, TotalDeletable } = action.payload;
            state.listOfTemplates = Results;
            state.totalDeletable = TotalDeletable;
        }
        state.getTemplateListStatus = 'success';
    })
        .addCase(getAllTemplateFromDB.rejected, (state) => {
        state.getTemplateListStatus = 'rejected';
    })
        .addCase(getTemplateById.pending, (state) => {
        state.getSingleTemplateStatus = 'pending';
    })
        .addCase(getTemplateById.fulfilled, (state, action) => {
        state.dynamicTemplate = action.payload;
        state.singleTemplate = action.payload;
        state.getSingleTemplateStatus = 'success';
    })
        .addCase(getTemplateById.rejected, (state) => {
        state.getSingleTemplateStatus = 'rejected';
    })
        .addCase(copyTemplate.fulfilled, (state, action) => {
        state.dynamicTemplate = action.payload;
    })
        .addCase(updateTemplate.pending, () => { })
        .addCase(updateTemplate.fulfilled, (state, action) => {
        state.listOfTemplates = [
            ..._.filter(state.listOfTemplates, (template) => !_.isEqual(template.Id, action.payload.Id)),
            action.payload,
        ];
    })
        .addCase(updateTemplate.rejected, () => { })
        .addCase(createDynamicTemplate.pending, () => { })
        .addCase(createDynamicTemplate.fulfilled, (state, action) => {
        state.totalDeletable += 1;
        state.listOfTemplates = [...state.listOfTemplates, action.payload];
        state.copiedTemplateOriginalName = '';
    })
        .addCase(createDynamicTemplate.rejected, () => { })
        .addCase(addNewQuestion.fulfilled, (state) => {
        state.singleTemplate = null;
    })
        .addCase(addNewQuestion.rejected, (state) => {
        state.singleTemplate = null;
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errMsg = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errMsg = action.payload;
    }),
});
export const { setSingleTemplateToNull, setHasFormError, setOriginalName } = templateSlice.actions;
export default templateSlice.reducer;

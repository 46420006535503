export const API_BASE_URL = `${window.location.origin}/api`;
export const SIGN_IN_START = 'signinstart';
export const SIGN_IN_END = 'signinend';
export const TOKEN = 'auth_token';
export const PHONE_SCREEN_WIDTH = 991;
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const MATERIAL_MIN_DATE = new Date(1900, 1, 1);
export const USER_ROLE_INFO = 'user_role_info';
export const CURRENT_USER_UNIQUE_ID = 'current_user_uid';
export const GRAPH_API_BASE_URL = 'https://graph.microsoft.com/v1.0';
export const TAB_NAME = {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
};
export const ONLY_DIGIT_REGEX = /^[0-9]+$/i;
export const FAMILY_MEMBERS = ['妻', '夫', '子', '父', '母', '妻の父', '妻の母', '夫の父', '夫の母'];
export const REDIRECT_URL = 'redirect_url';
export const DOMAIN = 'domain';
export const DRAWER_WIDTH = 220;
export const QUESTIONNAIRE_STATUS = {
    OPEN: 'open',
    CLOSED: 'closed',
    SCHEDULED: 'scheduled',
    CANCELED: 'cancelled',
};
export const DELIVERY_SETTINGS_MAP_ID = {
    '1': 'Heavy Rainfall',
    '2': 'Earthquake',
    '3': 'Windstorm',
    '4': 'Stormsurge',
    '5': 'Waves',
    '6': 'Blizzards',
    '7': 'Heavy Snow',
    '8': 'Tsunami',
};
export const SPECIFIC_RESULT_USER_RESPONSE_SCROLL_ID = 'user_response_view';
export const EMAIL_CHECK_REGEX = /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i;
export const IDLE_TIME = 1 * 60 * 60 * 1000;
export const CHANGED_ROLE = 'changed_role';

import { createSlice } from '@reduxjs/toolkit';
import { MULTI_DELETE_REDUCER } from 'store/name';
const initialState = {
    deletableRowData: [],
    isAllDeleteChecked: false,
    totalDeletableCount: 0,
};
const multiDeleteSlice = createSlice({
    name: MULTI_DELETE_REDUCER,
    initialState,
    reducers: {
        setAllDeleteChecked: (state, action) => {
            state.isAllDeleteChecked = action.payload;
        },
        setDeletableRowData: (state, action) => {
            if (typeof action.payload === 'string') {
                state.deletableRowData.push(action.payload);
            }
            else {
                state.deletableRowData = action.payload;
            }
        },
        setTotalDeletableCount: (state, action) => {
            state.totalDeletableCount = action.payload;
        },
        resetState: () => initialState,
    },
    extraReducers: () => { },
});
export const { setAllDeleteChecked, setTotalDeletableCount, setDeletableRowData, resetState } = multiDeleteSlice.actions;
export default multiDeleteSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TEMPLATE_REDUCER } from 'store/name';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
import { setTotalDeletableCount } from '../../ui/multi-delete';
import { handleToastOpt } from '../../ui/toast';
import { setOriginalName } from '.';
export const deleteTemplate = createAsyncThunk(`${TEMPLATE_REDUCER}/deleteTemplate`, async (id, { rejectWithValue }) => {
    try {
        await customAxios.delete(`/templates/delete-template/${id}`);
        return id;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteMultiTemplate = createAsyncThunk(`${TEMPLATE_REDUCER}/deleteMultiTemplate`, async (data, { rejectWithValue, dispatch }) => {
    try {
        await customAxios.post(`/templates/delete-template-bulk`, data);
        dispatch(handleToastOpt({
            open: true,
            message: `${data.ItemsToDelete.length} ${Language.jap.MultiTemplateDelete}`,
            severity: 'success',
        }));
        return data.ItemsToDelete;
    }
    catch {
        dispatch(handleToastOpt({
            open: true,
            message: Language.jap.CommonErrorMsg,
            severity: 'error',
        }));
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAllTemplateFromDB = createAsyncThunk(`${TEMPLATE_REDUCER}/getAllTemplateFromDB`, async (params, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/templates', {
            params: {
                ...params,
            },
        });
        if (response.data !== null) {
            dispatch(setTotalDeletableCount(response.data.TotalDeletable));
        }
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getTemplateById = createAsyncThunk(`${TEMPLATE_REDUCER}/getTemplateById`, async (templateId, { rejectWithValue }) => {
    try {
        const response = await customAxios.get(`/templates/${templateId}`);
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const copyTemplate = createAsyncThunk(`${TEMPLATE_REDUCER}/copyTemplate`, async (templateId, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get(`/templates/${templateId}`);
        dispatch(setOriginalName(response.data.Name));
        response.data.Name = `${response.data.Name}${Language.jap.CopyOf}`;
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const updateTemplate = createAsyncThunk(`${TEMPLATE_REDUCER}/updateTemplate`, async (dynamicTemplate, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/templates/update', dynamicTemplate);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const createDynamicTemplate = createAsyncThunk(`${TEMPLATE_REDUCER}/createDynamicTemplate`, async (dynamicTemplate, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/templates/create', dynamicTemplate);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

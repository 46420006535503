import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setCurrentPageValue, resetState, setShouldShowZeroPage } from 'store/reducers/ui/pagination';
export const useResetCurrentPage = () => {
    const dispatch = useAppDispatch();
    return useCallback(() => {
        dispatch(setCurrentPageValue(1));
    }, [dispatch]);
};
export const useResetPaginationState = () => {
    const dispatch = useAppDispatch();
    return useCallback(() => {
        dispatch(resetState());
    }, [dispatch]);
};
export const useSetShouldShowZeroPage = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setShouldShowZeroPage(value));
    }, [dispatch]);
};

import _ from 'lodash';
import { baseApi } from '../base-api';
import { BRANCH_TAGS } from '../tags';
const branchApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllBranches: build.query({
            query: () => {
                return {
                    url: '/branch-info',
                    method: 'get',
                };
            },
            providesTags: [BRANCH_TAGS.ALL_BRANCHES],
            transformResponse: (result) => {
                return _.orderBy(result, 'DisplayOrder', 'asc');
            },
        }),
        getAllPrefectures: build.query({
            query: () => {
                return {
                    url: '/branch-info/prefectures',
                    method: 'get',
                };
            },
            providesTags: [BRANCH_TAGS.ALL_PREFECTURES],
            transformResponse: (result) => {
                return _.orderBy(result, 'PrefectureCode', 'asc');
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetAllBranchesQuery, useGetAllPrefecturesQuery } = branchApi;
export default branchApi;

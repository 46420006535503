import { createAsyncThunk } from '@reduxjs/toolkit';
import { BRANCH_REDUCER } from 'store/name';
import { setTotalDeletableCount } from 'store/reducers/ui/multi-delete';
import { setTotalData } from 'store/reducers/ui/pagination';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
import branchApi from '../../../rtk-query/services/branch';
import { BRANCH_TAGS } from '../../../rtk-query/tags';
export const getAllBranch = createAsyncThunk(`${BRANCH_REDUCER}/getAllBranch`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/branch-info');
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAllBranchPaged = createAsyncThunk(`${BRANCH_REDUCER}/getAllBranchPaged`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/branch-info/paged', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        dispatch(setTotalDeletableCount(response.data.TotalDeletable));
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getPrefectureList = createAsyncThunk(`${BRANCH_REDUCER}/getPrefectureList`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/branch-info/prefectures');
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const addEditBranch = createAsyncThunk(`${BRANCH_REDUCER}/addEditBranch`, async (branchData, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.post('/branch-info/create-or-update-branch', branchData);
        const { Prefectures, ...others } = response.data;
        dispatch(branchApi.util.invalidateTags([BRANCH_TAGS.ALL_BRANCHES]));
        return {
            ...others,
            Prefectures: Prefectures.map((prefecture) => prefecture.PrefectureName),
        };
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getBranchInfoById = createAsyncThunk(`${BRANCH_REDUCER}/getBranchInfoById`, async (branchId, { rejectWithValue }) => {
    try {
        const response = await customAxios.get(`/branch-info/${branchId}`);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteBranch = createAsyncThunk(`${BRANCH_REDUCER}/deleteBranch`, async (branchId, { rejectWithValue }) => {
    try {
        await customAxios.delete(`/branch-info/delete-branch/${branchId}`);
        return branchId;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteMultiBranch = createAsyncThunk(`${BRANCH_REDUCER}/deleteMultiBranch`, async (data, { rejectWithValue }) => {
    try {
        await customAxios.post(`/branch-info/delete-branch-bulk`, data);
        return data.ItemsToDelete;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

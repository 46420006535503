import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setDeletableRowData, setAllDeleteChecked, resetState, setTotalDeletableCount, } from 'store/reducers/ui/multi-delete';
export const useSetDeletableRowData = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setDeletableRowData(value));
    }, [dispatch]);
};
export const useSetIsAllChecked = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setAllDeleteChecked(value));
    }, [dispatch]);
};
export const useResetMultiDeleteState = () => {
    const dispatch = useAppDispatch();
    return useCallback(() => {
        dispatch(resetState());
    }, [dispatch]);
};
export const UseSetTotalDeletableCount = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setTotalDeletableCount(value));
    }, [dispatch]);
};

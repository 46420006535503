export const AUTH_REDUCER = 'auth_reducer';
export const USER_REDUCER = 'user_reducer';
export const DRAWER_REDUCER = 'drawer_reducer';
export const DASHBOARD_REDUCER = 'dashboard_reducer';
export const SURVEY_REDUCER = 'survey_reducer';
export const BRANCH_REDUCER = 'branch_reducer';
export const DEPARTMENT_REDUCER = 'department_reducer';
export const TEMPLATE_REDUCER = 'template_reducer';
export const DELIVERY_SETTINGS_REDUCER = 'delivery_settings_reducer';
export const NORMAL_USER_REDUCER = 'normal_user_reducer';
export const TOAST_REDUCER = 'toast_reducer';
export const SPECIFIC_QUESTION_FILTER_PARAM_REDUCERS = 'specific_filter_param_reducer';
export const PAGINATION_REDUCER = 'pagination_reducer';
export const MULTI_DELETE_REDUCER = 'multi_delete_reducer';

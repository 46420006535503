import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { USER_REDUCER } from 'store/name';
import { EMPTY_GUID } from 'utils/constants';
import { getUserInfoByMail, getImageFromGraphApi, getAllUsersFromDBForCSV, getAllUsersFromDB, searchUser, syncUserOffice365, CSVUploadToDB, getImportHistory, deleteUser, getUserInfoById, unDeleteUser, addAnUser, deleteMultipleUser, uploadProfilePicture, removeProfilePicture, } from './api';
const initialState = {
    selectedUser: null,
    getUserInfoByMailStatus: 'initial',
    errorMessage: null,
    graphImageUrl: '',
    userListStoreForCSV: [],
    userListStore: [],
    stopScrolling: false,
    userListStoreDeleted: [],
    csvImport: false,
    userSynced: false,
    searchUserStatus: 'initial',
    importHistory: [],
    getAllUsersFromDBStatus: 'initial',
    getUserInfoByIdStatus: 'initial',
    addEditUserStatus: 'initial',
    deleteUserStatus: 'initial',
    updatedUserInfo: {
        hasUpdated: false,
        name: '',
        email: '',
    },
    deleteMultipleUserStatus: 'initial',
    getImportHistoryStatus: 'initial',
    totalDeletable: 0,
    addUserType: 'initial',
    profilePictureUrl: null,
    removeProfilePictureApiStatus: 'initial',
};
const userSlice = createSlice({
    name: USER_REDUCER,
    initialState,
    reducers: {
        resetUserList: (state) => {
            state.userListStore = [];
            state.userListStoreDeleted = [];
        },
        resetImportHistory: (state) => {
            state.importHistory = [];
        },
        resetAddUserType: (state) => {
            state.addUserType = 'initial';
        },
    },
    extraReducers: (builder) => builder
        .addCase(getUserInfoByMail.pending, (state) => {
        state.getUserInfoByMailStatus = 'pending';
    })
        .addCase(getUserInfoByMail.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
        state.profilePictureUrl = action.payload.ProfilePicUrl;
        state.getUserInfoByMailStatus = 'success';
    })
        .addCase(getUserInfoByMail.rejected, (state, action) => {
        state.getUserInfoByMailStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addCase(getImageFromGraphApi.fulfilled, (state, action) => {
        state.graphImageUrl = action.payload;
    })
        .addCase(getAllUsersFromDBForCSV.fulfilled, (state, action) => {
        state.userListStoreForCSV = action.payload;
    })
        .addCase(getAllUsersFromDB.pending, (state) => {
        state.getAllUsersFromDBStatus = 'pending';
    })
        .addCase(getAllUsersFromDB.rejected, (state) => {
        state.getAllUsersFromDBStatus = 'rejected';
    })
        .addCase(searchUser.pending, (state) => {
        state.searchUserStatus = 'pending';
        state.errorMessage = null;
    })
        .addCase(searchUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.searchUserStatus = 'rejected';
    })
        .addCase(syncUserOffice365.fulfilled, (state) => {
        state.userSynced = true;
    })
        .addCase(syncUserOffice365.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.userSynced = false;
    })
        .addCase(CSVUploadToDB.fulfilled, (state) => {
        state.csvImport = true;
    })
        .addCase(CSVUploadToDB.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.csvImport = false;
    })
        .addCase(getImportHistory.pending, (state) => {
        state.getImportHistoryStatus = 'pending';
    })
        .addCase(getImportHistory.fulfilled, (state, action) => {
        state.importHistory = action.payload;
        state.getImportHistoryStatus = 'success';
    })
        .addCase(getImportHistory.rejected, (state) => {
        state.getImportHistoryStatus = 'rejected';
    })
        .addCase(deleteUser.pending, (state) => {
        state.deleteUserStatus = 'pending';
    })
        .addCase(deleteUser.fulfilled, (state, action) => {
        state.totalDeletable = state.totalDeletable - 1;
        state.userListStore = state.userListStore.filter((user) => user.Id !== action.payload);
        state.deleteUserStatus = 'success';
    })
        .addCase(deleteUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.deleteUserStatus = 'rejected';
    })
        .addCase(getUserInfoById.pending, (state) => {
        state.getUserInfoByIdStatus = 'pending';
    })
        .addCase(getUserInfoById.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
        state.getUserInfoByIdStatus = 'success';
    })
        .addCase(getUserInfoById.rejected, (state) => {
        state.getUserInfoByIdStatus = 'rejected';
    })
        .addCase(unDeleteUser.fulfilled, (state, action) => {
        state.userListStoreDeleted = state.userListStoreDeleted.filter((user) => user.Id !== action.payload);
    })
        .addCase(addAnUser.pending, (state) => {
        state.addEditUserStatus = 'pending';
    })
        .addCase(addAnUser.fulfilled, (state, action) => {
        state.updatedUserInfo = {
            hasUpdated: Boolean(action.meta.arg.updatedClicked),
            name: action.meta.arg.Name,
            email: action.meta.arg.UserPrincipalName,
        };
        if (action.meta.arg.Id !== EMPTY_GUID) {
            state.userListStore = state.userListStore.map((user) => {
                if (user.Id === action.meta.arg.Id) {
                    return action.payload;
                }
                return user;
            });
        }
        else {
            state.userListStore.push(action.payload);
        }
        state.addUserType = action.meta.arg.Id === EMPTY_GUID ? 'added' : 'updated';
        state.addEditUserStatus = 'success';
    })
        .addCase(addAnUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.addEditUserStatus = 'rejected';
    })
        .addCase(deleteMultipleUser.pending, (state) => {
        state.deleteMultipleUserStatus = 'pending';
    })
        .addCase(deleteMultipleUser.fulfilled, (state, action) => {
        state.totalDeletable = state.totalDeletable - action.payload.length;
        state.userListStore = state.userListStore.filter((user) => !action.payload.includes(user.Id));
        state.deleteMultipleUserStatus = 'success';
    })
        .addCase(deleteMultipleUser.rejected, (state) => {
        state.deleteMultipleUserStatus = 'rejected';
    })
        .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.profilePictureUrl = action.payload;
    })
        .addCase(removeProfilePicture.pending, (state) => {
        state.removeProfilePictureApiStatus = 'pending';
    })
        .addCase(removeProfilePicture.fulfilled, (state) => {
        state.removeProfilePictureApiStatus = 'success';
    })
        .addCase(removeProfilePicture.rejected, (state) => {
        state.removeProfilePictureApiStatus = 'rejected';
    })
        .addMatcher(isAnyOf(searchUser.fulfilled, getAllUsersFromDB.fulfilled), (state, action) => {
        const { TotalDeletable, Results } = action.payload;
        const isDeleted = action.meta.arg.onlyDeletedUser;
        state.userListStore = isDeleted ? [] : Results;
        state.userListStoreDeleted = isDeleted ? Results : [];
        if (!isDeleted) {
            state.totalDeletable = TotalDeletable;
        }
        state.getAllUsersFromDBStatus = 'success';
        state.searchUserStatus = 'success';
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
        state.userSynced = false;
        state.csvImport = false;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { resetUserList, resetImportHistory, resetAddUserType } = userSlice.actions;
export default userSlice.reducer;

import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { USER_ROLE_INFO } from 'utils/constants';
import { USER_ROLE } from 'utils/user-role';
export * from './toast';
export * from './pagination';
export * from './multi-delete';
export * from './navigate';
export * from './split-location';
export const useCheckAdminRoutePermission = () => {
    return useCallback(() => {
        const roleInfo = localStorage.getItem(USER_ROLE_INFO);
        if (roleInfo !== null) {
            if (JSON.parse(roleInfo)) {
                const { UserRole } = JSON.parse(roleInfo);
                if (!isEqual(UserRole, USER_ROLE.ADMIN)) {
                    window.location.replace(`${window.location.origin}`);
                }
            }
        }
    }, []);
};
export const useCheckIfDepartmentManager = () => {
    const roleInfo = localStorage.getItem(USER_ROLE_INFO);
    if (roleInfo !== null) {
        if (JSON.parse(roleInfo)) {
            const { UserRole } = JSON.parse(roleInfo);
            return isEqual(UserRole, USER_ROLE.MANAGER);
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
};
export const useGetDomainName = () => {
    const { domain } = useParams();
    return domain;
};

import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { SURVEY_REDUCER } from 'store/name';
import { getQuestionAnswerResult, getFeedbackDetailsByNotificationId, addNewQuestion, getPandemicInformationById, sendSurveyData, closeQuestionnaire, getPandemicDetailsById, getRespondedListByPandemic, getNotRespondedListByPandemic, getExportedUserData, getFeedbackDetailsForNotResponded, getRecentlySentQuestionnaire, cancelScheduledQuestionnaire, } from './api';
const initialState = {
    surveyList: [],
    errorMessage: null,
    isLoading: false,
    stopScrolling: false,
    singleSurveyResult: undefined,
    feedbackDetailsInfo: null,
    pandemicInfo: null,
    surveyFeedbackStatusCode: null,
    getQuestionListApiStatus: 'initial',
    getSurveyResultsByIdApiStatus: 'initial',
    getFeedbackDetailsInfoStatus: 'initial',
    byPandemicRespondedList: {
        respondedList: [],
        stopScrolling: false,
    },
    byPandemicNotResponded: {
        notRespondedList: [],
        stopScrolling: false,
    },
    getPandemicByIdStatus: 'initial',
    getPandemicInformationByIdStatus: 'initial',
    getPandemicExportApiStatus: 'initial',
    pandemicExportedData: [],
    addQuestionApiStatus: 'initial',
    recentPandemicList: [],
    cancelQuestionnaireStatus: 'initial',
};
const surveySlice = createSlice({
    name: SURVEY_REDUCER,
    initialState,
    reducers: {
        resetSurveyList: (state) => {
            state.surveyList = [];
        },
        resetRespondedList: (state) => {
            state.byPandemicRespondedList.respondedList = [];
        },
        resetNotRespondedList: (state) => {
            state.byPandemicNotResponded.notRespondedList = [];
        },
    },
    extraReducers: (builder) => builder
        .addCase(getQuestionAnswerResult.pending, (state) => {
        state.getQuestionListApiStatus = 'pending';
    })
        .addCase(getQuestionAnswerResult.fulfilled, (state, action) => {
        state.surveyList = action.payload;
        state.getQuestionListApiStatus = 'success';
    })
        .addCase(getQuestionAnswerResult.rejected, (state) => {
        state.getQuestionListApiStatus = 'rejected';
    })
        .addCase(getPandemicDetailsById.pending, (state) => {
        state.getSurveyResultsByIdApiStatus = 'pending';
        state.singleSurveyResult = undefined;
    })
        .addCase(getPandemicDetailsById.fulfilled, (state, action) => {
        state.singleSurveyResult = action.payload;
        state.getSurveyResultsByIdApiStatus = 'success';
    })
        .addCase(getPandemicDetailsById.rejected, (state) => {
        state.getSurveyResultsByIdApiStatus = 'rejected';
    })
        .addCase(addNewQuestion.pending, (state) => {
        state.addQuestionApiStatus = 'pending';
    })
        .addCase(addNewQuestion.fulfilled, (state) => {
        state.addQuestionApiStatus = 'success';
    })
        .addCase(addNewQuestion.rejected, (state) => {
        state.addQuestionApiStatus = 'rejected';
    })
        .addCase(getPandemicInformationById.pending, (state) => {
        state.getPandemicInformationByIdStatus = 'pending';
    })
        .addCase(getPandemicInformationById.fulfilled, (state, action) => {
        state.pandemicInfo = action.payload;
        state.getPandemicInformationByIdStatus = 'success';
    })
        .addCase(getPandemicInformationById.rejected, (state) => {
        state.getPandemicInformationByIdStatus = 'rejected';
    })
        .addCase(sendSurveyData.fulfilled, (state, action) => {
        state.surveyFeedbackStatusCode = action.payload.status === 200 ? 200 : null;
        state.surveyList = _.uniqBy([action.payload.data, ...state.surveyList], 'Id');
    })
        .addCase(sendSurveyData.rejected, (state, action) => {
        state.surveyFeedbackStatusCode = 500;
        state.errorMessage = action.payload;
    })
        .addCase(closeQuestionnaire.fulfilled, (state, action) => {
        state.surveyList = state.surveyList.map((survey) => survey.Id === action.payload.Id ? action.payload : survey);
    })
        .addCase(getRespondedListByPandemic.pending, (state) => {
        state.byPandemicRespondedList.stopScrolling = false;
        state.getPandemicByIdStatus = 'pending';
    })
        .addCase(getRespondedListByPandemic.fulfilled, (state, action) => {
        state.byPandemicRespondedList.respondedList = action.payload;
        state.getPandemicByIdStatus = 'success';
    })
        .addCase(getRespondedListByPandemic.rejected, (state) => {
        state.byPandemicRespondedList.stopScrolling = false;
        state.getPandemicByIdStatus = 'rejected';
    })
        .addCase(getNotRespondedListByPandemic.pending, (state) => {
        state.byPandemicNotResponded.stopScrolling = false;
        state.getSurveyResultsByIdApiStatus = 'pending';
    })
        .addCase(getNotRespondedListByPandemic.fulfilled, (state, action) => {
        state.byPandemicNotResponded.notRespondedList = action.payload;
        state.getSurveyResultsByIdApiStatus = 'success';
    })
        .addCase(getNotRespondedListByPandemic.rejected, (state) => {
        state.byPandemicNotResponded.stopScrolling = false;
        state.getSurveyResultsByIdApiStatus = 'rejected';
    })
        .addCase(getExportedUserData.pending, (state) => {
        state.getPandemicExportApiStatus = 'pending';
    })
        .addCase(getExportedUserData.fulfilled, (state, action) => {
        state.getPandemicExportApiStatus = 'success';
        state.pandemicExportedData = action.payload;
    })
        .addCase(getExportedUserData.rejected, (state) => {
        state.getPandemicExportApiStatus = 'rejected';
    })
        .addCase(getRecentlySentQuestionnaire.fulfilled, (state, action) => {
        state.recentPandemicList = action.payload;
    })
        .addCase(cancelScheduledQuestionnaire.pending, (state) => {
        state.cancelQuestionnaireStatus = 'pending';
    })
        .addCase(cancelScheduledQuestionnaire.fulfilled, (state) => {
        state.cancelQuestionnaireStatus = 'success';
    })
        .addCase(cancelScheduledQuestionnaire.rejected, (state) => {
        state.cancelQuestionnaireStatus = 'rejected';
    })
        .addMatcher(isAnyOf(getFeedbackDetailsByNotificationId.pending, getFeedbackDetailsForNotResponded.pending), (state) => {
        state.getFeedbackDetailsInfoStatus = 'pending';
        state.feedbackDetailsInfo = null;
    })
        .addMatcher(isAnyOf(getFeedbackDetailsByNotificationId.fulfilled, getFeedbackDetailsForNotResponded.fulfilled), (state, action) => {
        state.getFeedbackDetailsInfoStatus = 'success';
        state.feedbackDetailsInfo = action.payload;
    })
        .addMatcher(isAnyOf(getFeedbackDetailsByNotificationId.rejected, getFeedbackDetailsForNotResponded.rejected), (state) => {
        state.getFeedbackDetailsInfoStatus = 'rejected';
        state.feedbackDetailsInfo = null;
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { resetSurveyList, resetRespondedList, resetNotRespondedList } = surveySlice.actions;
export default surveySlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEPARTMENT_REDUCER } from 'store/name';
import { setTotalDeletableCount } from 'store/reducers/ui/multi-delete';
import { setTotalData } from 'store/reducers/ui/pagination';
import departmentApi from 'store/rtk-query/services/department/department';
import { DEPARTMENT_TAGS } from 'store/rtk-query/tags';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
export const getAllDepartments = createAsyncThunk(`${DEPARTMENT_REDUCER}/getAllDepartments`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/department-info');
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getDepartmentListPaged = createAsyncThunk(`${DEPARTMENT_REDUCER}/getDepartmentListPaged`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/department-info/paged', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        dispatch(setTotalDeletableCount(response.data.TotalDeletable));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const addEditNewDepartment = createAsyncThunk(`${DEPARTMENT_REDUCER}/addEditNewDepartment`, async (departmentData, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.post('/department-info/create-or-update-department', departmentData);
        dispatch(departmentApi.util.invalidateTags([DEPARTMENT_TAGS.ALL_DEPARTMENTS]));
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getDepartmentInfoById = createAsyncThunk(`${DEPARTMENT_REDUCER}/getDepartmentInfoById`, async (id, { rejectWithValue }) => {
    try {
        const response = await customAxios.get(`/department-info/${id}`);
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteSingleDepartmentById = createAsyncThunk(`${DEPARTMENT_REDUCER}/deleteSingleDepartmentById`, async (id, { rejectWithValue }) => {
    try {
        await customAxios.delete(`/department-info/delete-department/${id}`);
        return id;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteMultipleDepartment = createAsyncThunk(`${DEPARTMENT_REDUCER}/deleteMultipleDepartment`, async (data, { rejectWithValue }) => {
    try {
        await customAxios.post('/department-info/delete-department-bulk', data);
        return data.ItemsToDelete;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

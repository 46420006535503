import * as _ from 'lodash';
import { EMPTY_GUID } from 'utils/constants';
import { baseApi } from '../../base-api';
import { DEPARTMENT_TAGS } from '../../tags';
import { transformDptV2 } from './helpers/transform-dpt-response/transform-dpt-v2';
const departmentApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllDepartments: build.query({
            query: () => {
                return {
                    url: '/department-info',
                    method: 'get',
                };
            },
            providesTags: [DEPARTMENT_TAGS.ALL_DEPARTMENTS],
            transformResponse: (result) => {
                return _.orderBy(result, 'DisplayOrder', 'asc').filter((value) => value.Id !== EMPTY_GUID);
            },
        }),
        getAllDepartmentsHierarchy: build.query({
            query: () => {
                return {
                    url: '/department-info/get-by-hierarchy',
                    method: 'get',
                };
            },
            providesTags: [DEPARTMENT_TAGS.ALL_DEPARTMENTS_BY_HIERARCHY],
            transformResponse: (result) => {
                return transformDptV2(result);
            },
        }),
        syncDepartments: build.mutation({
            query: () => {
                return {
                    url: '',
                    method: 'post',
                };
            },
            invalidatesTags: [...Object.values(DEPARTMENT_TAGS)],
        }),
    }),
    overrideExisting: false,
});
export const { useGetAllDepartmentsQuery, useGetAllDepartmentsHierarchyQuery } = departmentApi;
export default departmentApi;

import isEqual from 'lodash/isEqual';
import { useState, useEffect } from 'react';
import { ROUTES } from 'components/utility/role-navigation';
import { CHANGED_ROLE, USER_ROLE_INFO } from 'utils/constants';
import { USER_ROLE } from 'utils/user-role';
export const useGetRoleNav = () => {
    const [roleNav, setRoleNav] = useState('');
    useEffect(() => {
        const roleInfo = localStorage.getItem(USER_ROLE_INFO);
        const changedRole = localStorage.getItem(CHANGED_ROLE);
        if (changedRole === null) {
            if (roleInfo !== null) {
                if (JSON.parse(roleInfo)) {
                    const { UserRole } = JSON.parse(roleInfo);
                    if (isEqual(UserRole, USER_ROLE.ADMIN)) {
                        setRoleNav(ROUTES.ADMIN);
                    }
                    else if (isEqual(UserRole, USER_ROLE.MANAGER)) {
                        setRoleNav(ROUTES.DEPARTMENT_ADMIN);
                    }
                    else {
                        setRoleNav(ROUTES.GENERAL_USER);
                    }
                }
            }
        }
        else {
            if (changedRole === USER_ROLE.USER) {
                setRoleNav(ROUTES.GENERAL_USER);
            }
        }
    }, []);
    return roleNav;
};

import { createApi } from '@reduxjs/toolkit/query/react';
import customAxios from 'utils/custom-axios';
import { TAGS } from './tags';
const axiosBaseQuery = () => async ({ url, method, axiosConfigs }) => {
    try {
        const result = await customAxios({ url: url, method, ...axiosConfigs });
        return { data: result.data };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            },
        };
    }
};
export const baseApi = createApi({
    reducerPath: 'rtk_query',
    tagTypes: [...TAGS],
    baseQuery: axiosBaseQuery(),
    endpoints: () => ({}),
});

import { createSlice } from '@reduxjs/toolkit';
import { NORMAL_USER_REDUCER } from 'store/name';
import { getFeedbackDetailsByNotificationId } from '../survey/api';
import { getLastSentForFamily, getSurveyListForFamily, getFamilyResponseByPandemic } from './api';
const initialState = {
    lastSendForFamily: null,
    lastSendForFamilyApiStatus: 'initial',
    surveyListForUser: [],
    stopScrolling: false,
    familyResponseByPandemic: null,
    getFamilyResponseByPandemicApiStatus: 'initial',
    familyResponseByNotification: undefined,
    byNotificationApiStatus: 'initial',
    getFamilySurveyListStatus: 'initial',
};
const normalUserSlice = createSlice({
    name: NORMAL_USER_REDUCER,
    initialState,
    reducers: {
        resetSurveyListForUser: (state) => {
            state.surveyListForUser = [];
        },
    },
    extraReducers: (builder) => builder
        .addCase(getLastSentForFamily.pending, (state) => {
        state.lastSendForFamilyApiStatus = 'pending';
    })
        .addCase(getLastSentForFamily.fulfilled, (state, action) => {
        state.lastSendForFamily = action.payload;
        state.lastSendForFamilyApiStatus = 'success';
    })
        .addCase(getLastSentForFamily.rejected, (state) => {
        state.lastSendForFamilyApiStatus = 'rejected';
    })
        .addCase(getSurveyListForFamily.pending, (state) => {
        state.getFamilySurveyListStatus = 'pending';
    })
        .addCase(getSurveyListForFamily.fulfilled, (state, action) => {
        state.surveyListForUser = action.payload;
        state.getFamilySurveyListStatus = 'success';
    })
        .addCase(getSurveyListForFamily.rejected, (state) => {
        state.getFamilySurveyListStatus = 'rejected';
    })
        .addCase(getFamilyResponseByPandemic.pending, (state) => {
        state.getFamilyResponseByPandemicApiStatus = 'pending';
    })
        .addCase(getFamilyResponseByPandemic.fulfilled, (state, action) => {
        state.familyResponseByPandemic = action.payload;
        state.getFamilyResponseByPandemicApiStatus = 'success';
    })
        .addCase(getFamilyResponseByPandemic.rejected, (state) => {
        state.getFamilyResponseByPandemicApiStatus = 'rejected';
    })
        .addCase(getFeedbackDetailsByNotificationId.pending, (state) => {
        state.byNotificationApiStatus = 'pending';
    })
        .addCase(getFeedbackDetailsByNotificationId.fulfilled, (state, action) => {
        state.familyResponseByNotification = action.payload;
        state.byNotificationApiStatus = 'success';
    })
        .addCase(getFeedbackDetailsByNotificationId.rejected, (state) => {
        state.byNotificationApiStatus = 'rejected';
    }),
});
export const { resetSurveyListForUser } = normalUserSlice.actions;
export default normalUserSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { SPECIFIC_QUESTION_FILTER_PARAM_REDUCERS } from 'store/name';
const initialState = {
    branchId: '',
    departmentId: '',
    personalSafety: undefined,
    searchText: '',
    changeStatus: {
        personalSafety: 'initial',
        departmentId: 'initial',
        branchId: 'initial',
    },
    tabValue: '1',
    byBranchDptFilter: {},
};
const filterParamSlice = createSlice({
    name: SPECIFIC_QUESTION_FILTER_PARAM_REDUCERS,
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.branchId = typeof action.payload.branchId === 'string' ? action.payload.branchId : state.branchId;
            state.departmentId =
                typeof action.payload.departmentId === 'string' ? action.payload.departmentId : state.departmentId;
            state.personalSafety = 'personalSafety' in action.payload ? action.payload.personalSafety : state.personalSafety;
            state.searchText = typeof action.payload.searchText === 'string' ? action.payload.searchText : state.searchText;
            state.changeStatus.branchId =
                typeof action.payload.branchId === 'string' ? 'changed' : state.changeStatus.branchId;
            state.changeStatus.departmentId =
                typeof action.payload.departmentId === 'string' ? 'changed' : state.changeStatus.departmentId;
            state.changeStatus.personalSafety =
                typeof action.payload.personalSafety === 'string' ? 'changed' : state.changeStatus.personalSafety;
        },
        setTabValue: (state, action) => {
            state.tabValue = action.payload;
        },
        setParamsToInitialState: (state) => {
            state.branchId = '';
            state.departmentId = '';
            state.personalSafety = undefined;
            state.changeStatus = initialState.changeStatus;
        },
        resetSpecificQuestionState: (state) => {
            state.branchId = '';
            state.departmentId = '';
            state.personalSafety = undefined;
            state.searchText = '';
            state.changeStatus = {
                personalSafety: 'initial',
                departmentId: 'initial',
                branchId: 'initial',
            };
        },
        setByBranchDptFilterParams: (state, action) => {
            state.byBranchDptFilter = action.payload;
        },
    },
    extraReducers: () => { },
});
export const { setFilterParams, resetSpecificQuestionState, setTabValue, setParamsToInitialState, setByBranchDptFilterParams, } = filterParamSlice.actions;
export default filterParamSlice.reducer;

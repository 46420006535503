const Language = {
    en: {
        CreateRequest: 'Create Request',
    },
    jap: {
        Login: 'ログイン',
        Sunday: '日',
        Monday: '月',
        Tuesday: '火',
        Wednessday: '水',
        Thursday: '木',
        Friday: '金',
        Saturday: '土',
        LblSignInWithEmail: 'メールアドレスでサインイン',
        PlEnterEmailAddress: 'メールアドレス',
        PlEnterPassword: 'パスワード',
        LblForgetPassword: 'パスワードを忘れましたか？',
        BtnSignIn: 'サインイン',
        BtnCancel: 'キャンセル',
        BtnAddEmployee: '追加',
        BtnSendQuestionnaire: '送信',
        BtnSendQuestionnaireDashboard: '安否確認を送信',
        BtnImport: 'インポート',
        BtnAddDepartment: '追加',
        BtnAddBranch: '追加',
        BtnBrowse: '参照',
        BtnImportEmployee: 'インポート',
        LblSingInWithOffic365: 'Microsoft 365アカウントでサインイン',
        LblApplicationTitle: '安否確認',
        NavDashboard: 'ダッシュボード',
        NavUserDashboard: '直近の回答内容',
        NavEmployee: '従業員',
        NavDepartment: '部署',
        NavBranch: '拠点',
        NavTemplate: 'テンプレートを選択する',
        NavSendQuestion: '集計結果',
        NavResponseQuestion: '集計結果',
        NavEmployeeImportHistory: 'インポート履歴',
        NavImportHistory: 'インポート履歴',
        AddNewEmployee: '従業員を追加する',
        Name: '名前',
        EmployeeEmailAddress: '社内メールアドレス',
        Password: 'パスワード',
        User: 'ユーザー',
        Administrator: '管理者',
        PersonalEmail: '個人メールアドレス',
        FamilyInfo: '家族情報',
        FamilyRegistration: '登録すると家族に対しても安否確認が行われます',
        RelationShip: '関係',
        FamilyMemberMail: 'メールアドレス',
        TotalEmployee: '総従業員数',
        TotalDepartments: '総部署数',
        TotalBranches: '総拠点数',
        TotalSentQuestionnaires: '送信済安否確認数',
        TotalAverageResponse: '全体の回答率',
        SubTitleDashboard: '進行状況と集計結果',
        SubTitleEmployee: '従業員情報の管理と照会',
        SubTitleDepartment: '部署情報の管理',
        SubTitleBranch: '拠点情報の管理',
        SubTitleSendQuestion: '送信履歴一覧と照会',
        SubTitleUserAggregation: '家族の回答内容を確認できます。',
        SubTitleFeedbackQuestion: '集計結果一覧と照会',
        SummaryTitleSubject: '件名',
        SummaryTitleSendDate: '送信日',
        SummaryTitleStatus: '集計状況',
        SummaryTitleBranch: '拠点',
        SummaryTitleDepartment: '部署',
        TitleLastSendQuestion: '最後に送信した安否確認',
        TitleRecentlySendQuestion: '最近送信した安否確認',
        TitleLastSendQuestionDetails: '最後に送信した安否確認',
        NoOfEmployees: '従業員数',
        ResponseRate: '回答率',
        Responded: '回答済',
        RespondedWithRate: '回答済(率)',
        NotResponded: '未回答',
        SafePersonnel: '無事',
        StatusInProgress: '集計中',
        StatusClose: '完了',
        Search: '名前または個人メールアドレスを入力',
        EmployeeSearch: '名前または社内メールアドレスを入力',
        QuestionAnswerSearch: '名前または個人メールアドレスを入力',
        QuestionSearch: '件名を入力',
        ImportHistory: 'インポート履歴',
        ModifiedDate: '更新日',
        Action: '操作',
        SelectAll: 'すべて選択',
        ImportEmployees: '従業員データのインポート',
        FileUploaded: 'ファイルをアップロードしました',
        ClickToBrowseAndSelectFile: '「参照」を押してファイルを選択',
        FileLimitInformation: '5MB以下のファイル（csv形式）のみ',
        BtnDownloadSampleFile: 'サンプルファイルをダウンロード',
        BtnEditEmployee: '保存',
        BtnEditEmplyeeModalTitle: 'プロフィールを編集する',
        FileName: 'ファイル名',
        ImportedBy: '作成者',
        ExecutionTime: '作成日',
        DeleteEmployeeModalTitle: '従業員を削除する',
        DeleteDepartmentModalTitle: '部署を削除する',
        DeleteEmployeeMsg: 'を消去しますか',
        DeleteMultipleEmployeeMsg: '名の従業員が削除されます。よろしいですか？',
        BtnDelete: '削除',
        itemDeleteMsg: '件を選択中',
        AddDepartmentModalTitle: '部署を追加する',
        EditDepartmentModalTitle: '部署を編集する',
        DepartmentName: '部署名',
        Order: '並び順',
        BtnEditDepartment: '保存',
        DepartmentDeleteConfirmationMsg: 'を削除しますか',
        DepartmentMultiDeleteConfirmationMsg: '件の部署が削除されます。よろしいですか？',
        TemplateMultiDeleteConfirmationMsg: '件のテンプレートが削除されます。よろしいですか？',
        BranchDeleteModalTitle: '拠点を削除する',
        BranchDeleteConfirmationMsg: 'を削除しますか',
        BranchMultiDeleteCountMsg: '件の拠点が削除されます。よろしいですか？',
        AddBranchModalTitle: '拠点を追加する',
        BranchName: '拠点名',
        Prefectures: '所在地',
        TitleSendQuestionnaire: '安否確認を送信する',
        EnterDetails: '本文',
        QuestionnaireNotification: '回答締切日時',
        AutoResend1: '自動再送信',
        AutoResend2: '24時間以内に回答がない従業員に対して自動的に再送信を行います',
        LblQuestionAnswerTitle: '下記に記載の質問へ回答してください。',
        PersonalSaftyInformation: '本人の安否状況',
        AreYouAbleToWork: '出社可否',
        SafetyOfFamily: '家族の安否状況',
        ConditionOfHouse: '住宅被害',
        OtherStatus: '特記事項',
        PlaceHolderOtherStatus: 'その他を選択した場合、内容を記入してください',
        StatusSafe: '無事',
        StatusMinorInjury: '軽傷',
        StatusInjury: '重症',
        StatusOtherOption: 'その他',
        StatusYes: '可能',
        StatusNo: '不可',
        StatusNotSure: '不明',
        StatusGood: '無傷',
        StatusPartialDamage: '半壊',
        StatusMajorDamage: '全壊',
        StatusSeriousInjury: '重症',
        SendQuestion: 'アンケートを送信しました',
        EditBranchModalTitle: '拠点を編集する',
        BrnachDeleted: 'を削除しました',
        MultiBranchDeleted: '件の拠点を削除しました',
        BrnachUpdated: 'を更新しました',
        BranchAdded: '拠点を追加しました',
        DepartmentUpdated: 'を更新しました',
        DepartmentDeleted: 'を削除しました',
        MultiDepartmentDeleted: '件の部署を削除しました',
        DepartmentAdded: '部署を追加しました',
        Required: '必須',
        RequiredField: '必須の入力項目です',
        ResetPass: 'パスワードを変更する',
        PasswordLengthMsg: 'パスワードは6文字以上で入力してください',
        PasswordMatchMsg: 'パスワードが一致していません',
        PasswordCharacterExceedMsg: 'パスワードは35文字以下で入力してください',
        EmployeeUpdated: 'を更新しました',
        IUpdatedMyProfile: 'プロフィールを更新しました',
        EmployeeDeleted: 'を削除しました',
        MultiEmployeeDeleted: '名の従業員を削除しました',
        PasswordUpdated: 'パスワードを更新しました',
        NormalUserWelcome: '安否確認へようこそ',
        NormalUserInfoMsg: 'Microsoft 365のユーザーではない場合、本人・家族情報を編集するには下のボタンを使用してください。',
        UpdateProfile: 'プロフィール編集',
        ChangePassword: 'パスワードを変更する',
        LoginSuccess: 'ログインしました',
        From: 'From',
        To: 'To',
        EmployeeAdded: '従業員を追加しました',
        EmployeeImported: '社員情報をインポートしました',
        CsvHeaderError: 'CSVファイルのヘッダーは一部エラーが発生しています。確認してください。',
        CsvFileTypeError: 'CSVファイルをアップロードしてください',
        SelectDepartment: '部署を選択してください',
        SelectBranch: '拠点を選択してください',
        SelectRelation: '本人との関係を入力してください',
        LogOut: 'ログアウト',
        ResendMailInfo: '該当の従業員へ安否確認アンケートを再送信します',
        ResendFormModalTitle: '安否確認アンケートを再送信する',
        ResendBtn: '再送信',
        ResendSendToNonReply: '未回答の従業員のみ',
        ResendSendToAll: '宛先を変更しない',
        ResendSuccessFul: '再送信しました',
        ExportCSV: 'CSVエクスポート',
        RespondedBy: '回答者',
        SubmittedOn: '回答日時:',
        SubmittedUsing: '回答方法：',
        PersonalSafetyStatus: '安否状況',
        BtnCloseProcess: '締め切る',
        PersonalSafety: '安否状況',
        SentOn: '日付',
        ExpectedClosingDate: '回答締切日',
        PersonalSafetyTitle: '安否状況の集計結果',
        BtnClose: '閉じる',
        CloseQuestionModalTitle: '安否確認アンケートを締め切る',
        ChangeNotificationModalTitle: 'Change Notification Email And Password',
        Severe: '重症',
        RespondedTab: '回答済',
        NotRespondedTab: '未回答',
        ModalSubtitles: '必須フィールドにはアスタリスクがついています(*)',
        CloseQuestionInfoMsg: '本当に締め切りますか？',
        ResendModalInfoMsg: '回答締切日時',
        Viewall: '全て表示する',
        SyncOffice365UserModel: 'Microsoft 365のユーザー同期',
        SyncOffice365User: 'Microsoft 365のユーザー同期',
        ConfirmMsgSyncOffice365User: 'Microsoft 365のユーザーをCoo Kai 安否確認に同期しますか',
        BtnSync: '同期',
        Office365SyncToast: 'Microsoft 365のユーザーを同期しました',
        All: '全て',
        InvalidEmail: '無効なメールアドレスです',
        EmailLengthValidation: 'メールアドレスは500文字以内で入力してください',
        UserNameLengthValidation: 'ユーザー名は500文字以内で入力してください',
        BranchNameLengthValidation: '支店名は500文字以内で入力してください',
        PrefectureLengthValidation: '都道府県名は500文字以内で入力してください',
        DepartmentNameLengthValidation: '部署名は500文字以内で入力してください',
        PandemictNameLengthValidation: 'タイトルは500文字以内で入力してください',
        PandemictDescriptionLengthValidation: '本文は1000文字以内で入力してください',
        ThankYouAfterFeedbackFromMail: '安否確認へのご回答ありがとうございます。',
        CommonErrorMsg: 'エラーが発生しました。もう一度やり直してください。',
        MinDateError: '日付の範囲に誤りがあります',
        CLosingDateMinError: '現在の日時を過ぎた締切日時は設定できません.',
        CloseIconTitle: '締め切る',
        ResendIconTitle: '再送信',
        EditIconTitle: '編集',
        DeleteIconTitle: '削除',
        EmployeeDetails: '社員情報',
        IsAdministrator: 'は管理者',
        FamilyInformation: '家族情報',
        FamilyOptionHusband: '夫',
        FamilyOptionWife: '妻',
        FamilyOptionChildren: '子供',
        AllBranch: '全拠点',
        AllDepartment: '全部署',
        MinorInjury: '軽傷',
        CloseQuestionnaireMsg: '回答期間終了のためこのアンケートに回答することができません。',
        EmployeesImported: 'インポート成功',
        EmployeeNotImported: 'インポート失敗',
        ErrorLog: 'エラーログ',
        QuestionnaireDetailsTitle: '安否確認の内容',
        FilterOptions: 'フィルタオプション',
        TooltipDownloadIcon: 'インポートしたファイルをダウンロード',
        TooltipErrorLog: 'エラーログを出力',
        NewPassword: '新しいパスワード',
        AllTemplate: 'All Template',
        IsFailed: 'Is Failed',
        FailReason: 'Fail Reason',
        ViewTemplateTitle: '選択したアンケートのテンプレートを見る',
        ConfirmationMessageForAllUser: '全ての拠点と部署を選択しました。このまま続けますか？',
        BtnYes: 'はい',
        BtnNo: 'いいえ',
        BtnViewDetails: '詳細を見る',
        NavTemplateManagement: 'テンプレート',
        SubTitleTemplateManagement: 'テンプレート情報の管理',
        BtnTemplateManagement: '追加',
        TemplateName: 'テンプレート名',
        AddNewTemplateModal: 'テンプレート',
        NewTemplateSubTitle: '必須フィールドにはアスタリスクが付いています ',
        TemplatePreview: 'プレビュー',
        TemplateTitle: 'のプレビュー',
        EditTemplateModal: 'Edit Template',
        AddNewQuestion: '質問を追加',
        FeelTheContent: '内容をご記入ください',
        Option: '選択肢',
        Question: '質問',
        TemplateDetails: '本文',
        DefaultQuestionLabel: '必須の質問',
        AreYouSafe: '本人の安否状況',
        BtnAddTemplate: '追加',
        BtnEdit: '保存',
        BtnAddOption: '選択肢を追加',
        CopyIconTitle: '複製',
        DeleteTemplateModal: 'テンプレートを削除する',
        TemplateBody: '本文',
        TemplateCreated: 'テンプレートを追加しました',
        TemplateEdited: 'テンプレートを更新しました',
        TemplateDeleted: 'テンプレートを削除しました',
        MultiTemplateDelete: '件のテンプレートを削除しました',
        CopyOf: 'のコピー',
        NavAutomaticDelivery: '自動配信​',
        NavDisasterHistory: 'Disaster History',
        AddDeliverySettingsModalTitle: '自動配信設定を追加する',
        EditDeliverySettingsModalTitle: '自動配信設定を編集する',
        SelectDisaster: '災害を選択',
        SelectSeismicIntensity: '対象となる震度を設定',
        BtnAddDeliverySettings: '追加',
        BtnSaveDeliverySettings: '追加',
        BtnEditDeliverySettings: '保存',
        DisasterType: '災害種別',
        SeismicIntensity: '震度',
        LastDelivery: '最終送信日',
        Enabled: '有効',
        Disabled: '無効',
        IsEnabled: '自動配信',
        DeliverySettingsAdded: '自動配信設定を追加しました',
        DeliverySettingsDeleteModalTitle: '自動配信設定を削除する',
        DeliverySettingsDeleteConfirmationMsg: '削除しますか',
        DeliverySettingsMultiDeleteConfirmationMsg: '件の自動配信設定が削除されます。よろしいですか？',
        SettingsName: '設定名称',
        DeliveryAutoSending: '自動配信有効 オプションが有効になっている場合設定内容に基づいて安否確認が自動配信されます',
        IntensityExists: '選択した震度の設定は既に存在します',
        NavDeliverySettings: '自動配信設定',
        HeavyRainfall: '大雨特別警報',
        Earthquake: '地震',
        IntensityDefaultOption: '自動配信対象となる震度を設定してください',
        DepartmentManager: '部署管理者',
        DeliveryTarget: '配信対象',
        PermissionError: 'ログインする権限がありません',
        ActiveUserList: '有効なユーザー',
        DeletedUserList: '削除済みのユーザー',
        ActiveUser: 'ユーザーの有効化',
        ActiveEmployeeMsg: 'を有効にしますか？',
        BtnActive: '有効化',
        ExportCSVModalTitle: '従業員エクスポート',
        BtnExportGeneral: 'エクスポート',
        TabActiveUsers: '有効なユーザー',
        TabDeletedUsers: '削除済みのユーザー',
        EmployeeActivated: 'は有効化されました',
        AddNew: '追加',
        EmailSentStatus: 'メール送信状況',
        EmailDelivered: '成功',
        EmailPending: '処理中',
        Result: '集計結果',
        ResultTitle: '北海道地震',
        ResultSubTitle: '最後に送信した安否確認',
        NoDataMessage: '表示するデータがありません。',
        NoNumberAllowed: '入力内容に誤りがあります',
        NoOfFamily: '人数',
        InvalidEmailPassword: 'メールアドレスまたはパスワードが正しくありません',
        SetPastTimeError: '未来の日時を設定してください',
        EmployeeCount: '従業員数',
        AutoDeliveryTooltip: '自動配信による送信',
        SeriouslyInjured: '重傷',
        PhoneNumber: '電話番号',
        UploadProfile: 'プロフィール画像を変更',
        Save: '保存',
        BrowseImage: '新しい画像をアップロード',
        ScheduleSent: '送信予約する',
        ScheduleDate: '送信予約日時',
        Scheduled: '送信予約',
        Cancelled: '送信取消',
        WindStorm: '暴風',
        StormSurge: '高潮',
        Waves: '波浪',
        Blizzards: '暴風雪',
        HeavySnow: '大雪',
        CancelSend: '送信取消',
        QuestionnaireType: 'アンケートの種類',
        SafetyConfirmation: '安否確認',
        GeneralQuestionnaire: '一般アンケート',
        Tsunami: '大津波警報',
        PreferredSender: '優先送信者',
        SendTarget: '送信対象',
        ResponseStatus: '対応状況',
        SafetyStatus: '安否状況',
        Office365SyncStarted: '同期を開始しました。',
        LastUpdated: '最終更新日時',
        UserSentTime: '送信日時',
        CheckAnswer: '回答結果を確認する',
        RoleUser: '一般ユーザー',
        RoleManager: '部署管理者',
        RoleAdmin: 'システム管理者',
        Sender: '送信者',
        SendEmployee: '対象者数',
        TargetLocations: '対象拠点',
        TargetDepartment: '対象部署',
        NoDeadline: '締切なし',
    },
};
export default Language;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import ErrorFallback from 'components/error-fallback';
import Loading from 'components/loader';
import { ScreenSizeProvider } from 'components/utility/screen-size-context';
import Toast from 'components/utility/toast';
import { store } from 'store';
import { PHONE_SCREEN_WIDTH } from 'utils/constants';
import AppRoutes from './components/utility/app-routes';
import customTheme from './custom-theme';
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.APP_INSIGHT_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    },
});
appInsights.loadAppInsights();
const App = () => {
    const isWindowClient = typeof window === 'object';
    const [screenWidth, setScreenWidth] = useState(isWindowClient ? window.innerWidth : undefined);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const setSize = () => {
            setScreenWidth(window.innerWidth);
        };
        if (isWindowClient) {
            window.addEventListener('resize', setSize);
        }
        return () => window.removeEventListener('resize', setSize);
    }, [isWindowClient, setScreenWidth]);
    useEffect(() => {
        if (screenWidth) {
            setIsMobile(screenWidth <= PHONE_SCREEN_WIDTH);
        }
    }, [screenWidth]);
    useEffect(() => {
        moment.updateLocale('ja', {
            longDateFormat: {
                LL: 'YYYY年 M月 D日 (ddd) HH:mm',
                LLL: 'YYYY年 M月 D日 (ddd) HH:mm',
                LTS: 'YYYY年 M月 D日 (ddd) HH:mm',
                LT: 'YYYY年 M月 D日 (ddd) HH:mm',
                L: 'YYYY年 M月 D日 (ddd) HH:mm',
                LLLL: 'YYYY年 M月 D日 (ddd) HH:mm',
            },
        });
    }, []);
    return (_jsx(ThemeProvider, { theme: customTheme, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(Provider, { store: store, children: _jsx(ErrorBoundary, { FallbackComponent: ErrorFallback, children: _jsx(Suspense, { fallback: _jsx(Loading, { loading: true }), children: _jsxs(ScreenSizeProvider, { value: {
                                isMobile,
                                screenWidth,
                            }, children: [_jsx(AppRoutes, {}), _jsx(Toast, {})] }) }) }) }) }) }));
};
export default withAITracking(reactPlugin, App, 'App', 'root-clone');

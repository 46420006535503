import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { BRANCH_REDUCER } from 'store/name';
import { EMPTY_GUID } from 'utils/constants';
import { getAllBranch, getAllBranchPaged, getPrefectureList, addEditBranch, getBranchInfoById, deleteBranch, deleteMultiBranch, } from './api';
const initialState = {
    branchList: [],
    stopScrolling: false,
    branchListPaged: [],
    errorMessage: null,
    prefectureList: [],
    singleBranchInfo: null,
    getAllBranchPagedStatus: 'initial',
    getBranchInfoByIdStatus: 'initial',
    deleteMultipleBranchStatus: 'initial',
    totalDeletable: 0,
};
const branchSlice = createSlice({
    name: BRANCH_REDUCER,
    initialState,
    reducers: {
        resetBranchList: (state) => {
            state.branchListPaged = [];
        },
        resetSelectBranch: (state) => {
            state.singleBranchInfo = null;
        },
    },
    extraReducers: (builder) => builder
        .addCase(getAllBranch.fulfilled, (state, action) => {
        state.branchList = action.payload;
    })
        .addCase(getAllBranchPaged.pending, (state) => {
        state.getAllBranchPagedStatus = 'pending';
    })
        .addCase(getAllBranchPaged.fulfilled, (state, action) => {
        const { Results, TotalDeletable } = action.payload;
        state.branchListPaged = Results;
        state.totalDeletable = TotalDeletable;
        state.getAllBranchPagedStatus = 'success';
    })
        .addCase(getAllBranchPaged.rejected, (state, action) => {
        state.getAllBranchPagedStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addCase(getPrefectureList.fulfilled, (state, action) => {
        state.prefectureList = _.orderBy(action.payload, 'PrefectureCode', 'asc');
    })
        .addCase(addEditBranch.fulfilled, (state, action) => {
        if (action.meta.arg.Id !== EMPTY_GUID) {
            state.branchListPaged = state.branchListPaged.map((branch) => {
                if (branch.Id === action.meta.arg.Id) {
                    return action.payload;
                }
                return branch;
            });
        }
    })
        .addCase(getBranchInfoById.pending, (state) => {
        state.getBranchInfoByIdStatus = 'pending';
    })
        .addCase(getBranchInfoById.fulfilled, (state, action) => {
        state.singleBranchInfo = action.payload;
        state.getBranchInfoByIdStatus = 'success';
    })
        .addCase(getBranchInfoById.rejected, (state, action) => {
        state.getBranchInfoByIdStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addCase(deleteBranch.fulfilled, (state, action) => {
        state.totalDeletable -= 1;
        state.branchListPaged = state.branchListPaged.filter((x) => x.Id !== action.payload);
    })
        .addCase(deleteMultiBranch.pending, (state) => {
        state.deleteMultipleBranchStatus = 'pending';
    })
        .addCase(deleteMultiBranch.fulfilled, (state, action) => {
        state.totalDeletable = state.totalDeletable - action.payload.length;
        state.branchListPaged = state.branchListPaged.filter((x) => !action.payload.includes(x.Id));
        state.deleteMultipleBranchStatus = 'success';
    })
        .addCase(deleteMultiBranch.rejected, (state, action) => {
        state.deleteMultipleBranchStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { resetBranchList, resetSelectBranch } = branchSlice.actions;
export default branchSlice.reducer;

import { EMPTY_GUID } from 'utils/constants';
function createParentChildRelationship(data) {
    const ROOT_GROUP = data.find((value) => value.ParentId === EMPTY_GUID);
    const departmentMap = new Map();
    const rootDepartments = [];
    data.forEach((item) => {
        departmentMap.set(item.Id, { ...item, children: [] });
    });
    data.forEach((item) => {
        if (item.ParentId === EMPTY_GUID) {
            rootDepartments.push(departmentMap.get(item.Id));
        }
        else {
            if (item.ParentId != null) {
                const parent = departmentMap.get(item.ParentId);
                if (parent) {
                    parent.children.push({
                        ...departmentMap.get(item.Id),
                        ParentId: item.ParentId,
                    });
                }
            }
            else {
                const root = departmentMap.get(ROOT_GROUP?.Id);
                if (root) {
                    root.children.push(departmentMap.get(item.Id));
                }
            }
        }
    });
    return rootDepartments;
}
export const transformDptV2 = (departments) => {
    const departmentHierarchy = createParentChildRelationship(departments);
    return [...(departmentHierarchy[0]?.children ?? [])];
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from 'store/name';
import { setTotalDeletableCount } from 'store/reducers/ui/multi-delete';
import { setTotalData } from 'store/reducers/ui/pagination';
import { GRAPH_API_BASE_URL } from 'utils/constants';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
import { setUserRole } from '../auth';
export const getUserInfoByMail = createAsyncThunk(`${USER_REDUCER}/getUserInfoByMail`, async (mail, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/user-info/get-by-upn', {
            params: {
                upn: mail,
            },
        });
        const { FamilyMemberInfoes, IsAdmin, ...others } = response.data;
        const data = {
            ...others,
            giveFamilyInfo: !!FamilyMemberInfoes && FamilyMemberInfoes.length > 0,
            IsAdmin: IsAdmin ? '1' : '0',
            FamilyMemberInfoes,
        };
        dispatch(setUserRole(data.UserRole));
        return data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getImageFromGraphApi = createAsyncThunk(`${USER_REDUCER}/getImageFromGraphApi`, async (mail, { rejectWithValue }) => {
    try {
        const url = `${GRAPH_API_BASE_URL}/users/${mail}/photos('96x96')/$value`;
        const response = await customAxios.get(url, { responseType: 'blob' });
        return URL.createObjectURL(response.data);
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAllUsersFromDBForCSV = createAsyncThunk(`${USER_REDUCER}/getAllUsersFromDBForCSV`, async (data, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/user-info/all-users', {
            params: {
                ...data,
            },
        });
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getAllUsersFromDB = createAsyncThunk(`${USER_REDUCER}/getAllUsersFromDB`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/user-info', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        dispatch(setTotalDeletableCount(response.data.TotalDeletable));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const searchUser = createAsyncThunk(`${USER_REDUCER}/searchUser`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('user-info/get-users-by-search', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        dispatch(setTotalDeletableCount(response.data.TotalDeletable));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const syncUserOffice365 = createAsyncThunk(`${USER_REDUCER}/syncUserOffice365`, async (overriteAll, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/user-sync', {}, {
            params: {
                overriteAll,
            },
        });
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const CSVUploadToDB = createAsyncThunk(`${USER_REDUCER}/CSVUploadToDB`, async (fileData, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append('file', fileData);
        await customAxios.post('/user-info/user-csv', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return true;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getImportHistory = createAsyncThunk(`${USER_REDUCER}/getImportHistory`, async (params, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get(`/user-info/import-history`, {
            params: {
                ...params,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        return response.data.Results.map((data) => ({
            ...data,
            ErrorCSV: !!(data.NotImportedEmployees && data.NotImportedEmployees > 0),
            IsDeletable: true,
        }));
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteUser = createAsyncThunk(`${USER_REDUCER}/deleteUser`, async (userId, { rejectWithValue }) => {
    try {
        await customAxios.delete(`/user-info/${userId}`);
        return userId;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getUserInfoById = createAsyncThunk(`${USER_REDUCER}/getUserInfoById`, async (userId, { rejectWithValue }) => {
    try {
        const response = await customAxios.get(`/user-info/${userId}`);
        const { FamilyMemberInfoes, IsAdmin, ...others } = response.data;
        const data = {
            ...others,
            giveFamilyInfo: !!FamilyMemberInfoes && FamilyMemberInfoes.length > 0,
            IsAdmin: IsAdmin ? '1' : '0',
            FamilyMemberInfoes,
        };
        return data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const unDeleteUser = createAsyncThunk(`${USER_REDUCER}/unDeleteUser`, async (userId, { rejectWithValue }) => {
    try {
        await customAxios.post('/user-info/undelete-user', {}, {
            params: {
                userId,
            },
        });
        return userId;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const addAnUser = createAsyncThunk(`${USER_REDUCER}/addAnUser`, async (userData, { rejectWithValue }) => {
    try {
        const { updatedClicked, ...data } = userData;
        const response = await customAxios.post('/user-info', data);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const resetPassword = createAsyncThunk(`${USER_REDUCER}/resetPassword`, async (data, { rejectWithValue }) => {
    try {
        await customAxios.post('/user-info/change-pass', data);
        return true;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteMultipleUser = createAsyncThunk(`${USER_REDUCER}/deleteMultipleUser`, async (data, { rejectWithValue }) => {
    try {
        await customAxios.post('/user-info/delete-user-bulk', data);
        return data.ItemsToDelete;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const uploadProfilePicture = createAsyncThunk(`${USER_REDUCER}/uploadProfilePicture`, async (uploadProfileParams, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/user-info/upload-profile-picture', {
            FileString: uploadProfileParams.fileString,
            IsLocalImage: uploadProfileParams.isLocalImage,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const removeProfilePicture = createAsyncThunk(`${USER_REDUCER}/removeProfilePicture`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/user-info/remove-profile-picture');
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

import isEmpty from 'lodash/isEmpty';
import { ROUTES } from 'components/utility/role-navigation';
import { store } from 'store';
import authApi from 'store/rtk-query/custom/auth-api';
import { TOKEN } from './constants';
export const setAccessTokenToLocalStorage = function setAccessTokenToLocalStorage(token, isO365 = true) {
    if (!isEmpty(token)) {
        const now = new Date();
        if (isO365) {
            now.setMinutes(now.getMinutes() + 50);
        }
        else {
            now.setMinutes(now.getMinutes() + 13 * 24 * 60);
        }
        const expDate = new Date(now);
        const tokenObj = { token, expireAt: expDate, isO365 };
        localStorage.setItem(TOKEN, JSON.stringify(tokenObj));
    }
    else {
        window.localStorage.clear();
        window.location.replace(`${ROUTES.LOGIN}`);
    }
};
async function refreshAccessToken(currentUserUniqueId) {
    const { data } = await store.dispatch(authApi.endpoints.refreshToken.initiate(currentUserUniqueId));
    if (data) {
        setAccessTokenToLocalStorage(data, true);
    }
    return data;
}
export { refreshAccessToken };

import { createApi } from '@reduxjs/toolkit/query';
import axios from 'axios';
import { API_BASE_URL } from 'utils/constants';
const axiosBaseQuery = () => async ({ url, method, axiosConfigs }) => {
    try {
        const result = await axios({ url: url, method, baseURL: API_BASE_URL, ...axiosConfigs });
        return { data: result.data };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            },
        };
    }
};
const authApi = createApi({
    reducerPath: 'rtk_auth_api',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['refresh_token'],
    endpoints: (build) => ({
        refreshToken: build.query({
            query: (uniqueId) => {
                return {
                    url: '/user-token',
                    method: 'get',
                    axiosConfigs: {
                        params: {
                            upn: uniqueId,
                        },
                    },
                };
            },
            providesTags: ['refresh_token'],
            keepUnusedDataFor: 0,
            forceRefetch: () => true,
        }),
    }),
});
export default authApi;

import { createTheme } from '@mui/material/styles';
const primary = '#249ff8';
const borderColor = '#e5e5e5';
const red = '#f44336';
const textBlack = '#333333';
const inputBorderColor = '#e5e5e5';
const customTheme = createTheme({
    typography: {
        fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
        fontSize: 16,
    },
    palette: {
        primary: {
            main: primary,
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: primary,
                    border: `2px solid ${primary}`,
                },
                root: {
                    color: primary,
                    textTransform: 'none',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: inputBorderColor,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    backgroundColor: primary,
                    '&:hover': {
                        backgroundColor: primary,
                    },
                },
            },
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        cursor: 'not-allowed',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: red,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    borderBottom: `1px solid ${borderColor}`,
                    color: textBlack,
                },
                body: {
                    borderBottom: `1px solid ${borderColor}`,
                    color: textBlack,
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: textBlack,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
    },
});
export default customTheme;

import * as _ from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const useSplitLocationHook = () => {
    const [splitLocation, setSplitLocation] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const path = location.pathname
            .replace(/^.{0}\//g, '')
            .trim()
            .split('/')
            .filter((item) => !_.isEmpty(item));
        setSplitLocation(path);
    }, [location]);
    return splitLocation;
};

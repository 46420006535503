import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { handleToastOpt } from 'store/reducers/ui/toast';
export const useShowToast = () => {
    const dispatch = useAppDispatch();
    return useCallback((message, severity) => {
        dispatch(handleToastOpt({
            severity,
            message,
            open: true,
        }));
    }, [dispatch]);
};

import { createSlice } from '@reduxjs/toolkit';
import { PAGINATION_REDUCER } from 'store/name';
const initialState = {
    currentPage: 1,
    rowPerPage: 20,
    totalDataCount: 1,
    shouldShowZeroPage: false,
};
const paginationSlice = createSlice({
    name: PAGINATION_REDUCER,
    initialState,
    reducers: {
        setRecordPerPage: (state, action) => {
            state.rowPerPage = action.payload;
            state.currentPage = 1;
        },
        setCurrentPageValue: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalDataCount = action.payload;
            state.shouldShowZeroPage = action.payload === 0;
        },
        setShouldShowZeroPage: (state, action) => {
            state.shouldShowZeroPage = action.payload;
        },
        resetState: () => initialState,
    },
    extraReducers: () => { },
});
export const { setRecordPerPage, setCurrentPageValue, setTotalData, resetState, setShouldShowZeroPage } = paginationSlice.actions;
export default paginationSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { isPendingActionMatcher, isRejectedActionMatcher } from 'store/matchers';
import { AUTH_REDUCER } from 'store/name';
import Language from 'utils/language';
import { getAccessToken, getAccessTokenByCode, getUserRoleInfo, getAccessTokenByUserPassword } from './api';
const initialState = {
    accessTokenResult: null,
    errorMessage: null,
    getAccessTokenStatus: 'initial',
    getAccessTokenByCodeStatus: 'initial',
    roleInfo: '',
    getUserRoleInfoStatus: 'initial',
    getAccessTokenByUserPasswordStatus: 'initial',
    userStatus: 'loggedOut',
};
const authSlice = createSlice({
    name: AUTH_REDUCER,
    initialState,
    reducers: {
        setUserRole(state, action) {
            state.roleInfo = action.payload;
        },
        setUserLoggedInStatus(state, action) {
            state.userStatus = action.payload;
        },
    },
    extraReducers: (builder) => builder
        .addCase(getAccessToken.pending, (state) => {
        state.getAccessTokenStatus = 'pending';
    })
        .addCase(getAccessToken.fulfilled, (state, action) => {
        state.getAccessTokenStatus = 'success';
        state.accessTokenResult = action.payload;
    })
        .addCase(getAccessToken.rejected, (state) => {
        state.getAccessTokenStatus = 'rejected';
        state.errorMessage = Language.jap.CommonErrorMsg;
    })
        .addCase(getAccessTokenByCode.pending, (state) => {
        state.getAccessTokenByCodeStatus = 'pending';
    })
        .addCase(getAccessTokenByCode.fulfilled, (state, action) => {
        state.accessTokenResult = action.payload;
        state.getAccessTokenByCodeStatus = 'success';
    })
        .addCase(getAccessTokenByCode.rejected, (state) => {
        state.getAccessTokenByCodeStatus = 'rejected';
    })
        .addCase(getUserRoleInfo.pending, (state) => {
        state.getUserRoleInfoStatus = 'pending';
    })
        .addCase(getUserRoleInfo.fulfilled, (state, action) => {
        state.getUserRoleInfoStatus = 'success';
        state.roleInfo = action.payload;
    })
        .addCase(getUserRoleInfo.rejected, (state, action) => {
        state.getUserRoleInfoStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addCase(getAccessTokenByUserPassword.pending, (state) => {
        state.getAccessTokenByUserPasswordStatus = 'pending';
    })
        .addCase(getAccessTokenByUserPassword.fulfilled, (state, action) => {
        state.accessTokenResult = action.payload;
        state.getAccessTokenByUserPasswordStatus = 'success';
    })
        .addCase(getAccessTokenByUserPassword.rejected, (state, action) => {
        state.getAccessTokenByUserPasswordStatus = 'rejected';
        state.errorMessage = action.payload;
    })
        .addMatcher(isPendingActionMatcher, (state) => {
        state.errorMessage = null;
    })
        .addMatcher(isRejectedActionMatcher, (state, action) => {
        state.errorMessage = action.payload;
    }),
});
export const { setUserRole, setUserLoggedInStatus } = authSlice.actions;
export default authSlice.reducer;

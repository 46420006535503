import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SURVEY_REDUCER } from 'store/name';
import { setTotalData } from 'store/reducers/ui/pagination';
import dashboardApi from 'store/rtk-query/services/dashboard';
import { DASHBOARD_TAGS } from 'store/rtk-query/tags';
import { API_BASE_URL } from 'utils/constants';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
export const getQuestionAnswerResult = createAsyncThunk(`${SURVEY_REDUCER}/getQuestionAnswerResult`, async (data, { rejectWithValue, dispatch }) => {
    const { startDate, endDate, ...others } = data;
    try {
        const response = await customAxios.get('/pandemic', {
            params: {
                ...others,
                startDate: startDate || '',
                endDate: endDate || '',
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        return response.data.Results.map((date) => ({
            ...date,
            IsDeletable: true,
        }));
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getSurveyResultsById = createAsyncThunk(`${SURVEY_REDUCER}/getSurveyResultsById`, async (data, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/survey-result/by-pandemic', {
            params: {
                ...data,
            },
        });
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getFeedbackDetailsByNotificationId = createAsyncThunk(`${SURVEY_REDUCER}/getFeedbackDetailsByNotificationId`, async ({ notificationId, isFamilyMember = undefined }, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/survey-result/by-notification', {
            params: {
                notificationId,
                isFamilyMember: isFamilyMember === undefined ? null : isFamilyMember,
            },
        });
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const addNewQuestion = createAsyncThunk(`${SURVEY_REDUCER}/addNewQuestion`, async (questionData, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/notification', questionData);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getPandemicInformationById = createAsyncThunk(`${SURVEY_REDUCER}/getPandemicInformationById`, async (notificationId, { rejectWithValue }) => {
    try {
        const response = await axios.get('/pandemic/get-pandemic-by-notification', {
            params: {
                notificationId,
            },
            baseURL: API_BASE_URL,
        });
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const sendSurveyData = createAsyncThunk(`${SURVEY_REDUCER}/sendSurveyData`, async (surveyData, { rejectWithValue }) => {
    try {
        const response = await axios.post('/survey-result', surveyData, {
            baseURL: API_BASE_URL,
        });
        return {
            data: response.data,
            status: response.status,
        };
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const resendNotification = createAsyncThunk(`${SURVEY_REDUCER}/resendNotification`, async (data, { rejectWithValue }) => {
    try {
        await customAxios.post('/notification/resend', data);
        return true;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const closeQuestionnaire = createAsyncThunk(`${SURVEY_REDUCER}/closeQuestionnaire`, async (pandemicId, { rejectWithValue }) => {
    try {
        const response = await customAxios.post('/notification/close', {}, {
            params: {
                pandemicId,
            },
        });
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getPandemicDetailsById = createAsyncThunk(`${SURVEY_REDUCER}/getPandemicDetailsById`, async (data, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/survey-result/by-pandemic-id', {
            params: {
                ...data,
            },
        });
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getRespondedListByPandemic = createAsyncThunk(`${SURVEY_REDUCER}/getRespondedListByPandemic`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/survey-result/by-pandemic-responded', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        return response.data.Results.map((res) => ({
            ...res,
            IsDeletable: true,
        }));
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getNotRespondedListByPandemic = createAsyncThunk(`${SURVEY_REDUCER}/getNotRespondedListByPandemic`, async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.get('/survey-result/by-pandemic-not-responded', {
            params: {
                ...data,
            },
        });
        dispatch(setTotalData(response.data.TotalData));
        return response.data.Results.map((res) => ({
            ...res,
            IsDeletable: true,
        }));
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getExportedUserData = createAsyncThunk(`${SURVEY_REDUCER}/getExportedUserData`, async (data, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/survey-result/export-user-data', {
            params: {
                ...data,
            },
        });
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getFeedbackDetailsForNotResponded = createAsyncThunk(`${SURVEY_REDUCER}/getFeedbackDetailsForNotResponded`, async ({ notificationId }, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/survey-result/by-notification-not-responded', {
            params: {
                notificationId,
            },
        });
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getRecentlySentQuestionnaire = createAsyncThunk(`${SURVEY_REDUCER}/getRecentlySentQuestionnaire`, async () => {
    const response = await customAxios.get('/pandemic/recently-sent');
    return response.data.map((res) => ({
        ...res,
        IsDeletable: true,
    }));
});
export const cancelScheduledQuestionnaire = createAsyncThunk(`${SURVEY_REDUCER}/cancelScheduledQuestionnaire`, async (pandemicId, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.post('/notification/cancel-pandemic', {}, {
            params: {
                pandemicId,
            },
        });
        dispatch(dashboardApi.util.invalidateTags([...Object.values(DASHBOARD_TAGS)]));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const updateSurveyReport = createAsyncThunk(`${SURVEY_REDUCER}/updateSurveyReport`, async (pandemicId, { rejectWithValue }) => {
    try {
        const res = await customAxios.post('/survey-result/update-report', {}, {
            params: {
                pandemicId,
            },
        });
        window.location.reload();
        return res.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});

import { createSlice } from '@reduxjs/toolkit';
import { DRAWER_REDUCER } from 'store/name';
const initialState = {
    isDrawerOpen: true,
};
const drawerSlice = createSlice({
    name: DRAWER_REDUCER,
    initialState,
    reducers: {
        setDrawerState: (state, action) => {
            state.isDrawerOpen = action.payload;
        },
    },
});
export const { setDrawerState } = drawerSlice.actions;
export default drawerSlice.reducer;

export const selectTotalDeletableUser = (state) => state.user.totalDeletable;
export const selectMultipleDeleteUserStatus = (state) => state.user.deleteMultipleUserStatus;
export const selectAddUserType = (state) => state.user.addUserType;
export const selectCsvImport = (state) => state.user.csvImport;
export const selectUserSynced = (state) => state.user.userSynced;
export const selectActiveUsers = (state) => state.user.userListStore;
export const selectDeletedUsers = (state) => state.user.userListStoreDeleted;
export const selectSearchUserStatus = (state) => state.user.searchUserStatus;
export const selectGetAllUsersFromDBStatus = (state) => state.user.getAllUsersFromDBStatus;
export const selectGetUserInfoByIdStatus = (state) => state.user.getUserInfoByIdStatus;
export const selectAddEditUserStatus = (state) => state.user.addEditUserStatus;
export const selectDeleteUserStatus = (state) => state.user.deleteUserStatus;
export const selectDeleteMultipleUserStatus = (state) => state.user.deleteMultipleUserStatus;
export const selectSelectedUser = (state) => state.user.selectedUser;
export const selectUserListForCsv = (state) => state.user.userListStoreForCSV;
export const selectImportHistory = (state) => state.user.importHistory;
export const selectGetImportHistoryStatus = (state) => state.user.getImportHistoryStatus;
export const selectGetUserInfoByMailStatus = (state) => state.user.getUserInfoByMailStatus;
export const selectUpdatedUserInfo = (state) => state.user.updatedUserInfo;
export const selectGraphImageUrl = (state) => state.user.graphImageUrl;
export const selectProfilePictureUrl = (state) => state.user.profilePictureUrl;
export const selectRemoveProfilePictureApiStatus = (state) => state.user.removeProfilePictureApiStatus;

import axios from 'axios';
import { ROUTES } from 'components/utility/role-navigation';
import { refreshAccessToken } from './common';
import { API_BASE_URL, CURRENT_USER_UNIQUE_ID, TOKEN } from './constants';
const customAxios = axios.create({
    baseURL: API_BASE_URL,
});
customAxios.interceptors.request.use(async (config) => {
    const tokenObj = localStorage.getItem(TOKEN);
    if (tokenObj !== null) {
        const { token } = JSON.parse(tokenObj);
        config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
}, (error) => Promise.reject(error));
customAxios.interceptors.response.use((response) => response, async (error) => {
    const originalRequest = error.config;
    const tokenObj = localStorage.getItem(TOKEN);
    if (tokenObj !== null) {
        const { isO365 } = JSON.parse(tokenObj);
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (!!isO365) {
                const currentUser = localStorage.getItem(CURRENT_USER_UNIQUE_ID);
                const token = await refreshAccessToken(currentUser);
                axios.defaults.headers.common.Authorization = 'Bearer ' + token;
                originalRequest._retry = true;
                return customAxios(originalRequest);
            }
            else {
                originalRequest._retry = true;
                window.localStorage.clear();
                window.location.replace(`${ROUTES.LOGIN}`);
            }
        }
    }
    return Promise.reject(error);
});
export default customAxios;
